<template>
    <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full">
            <div>
                <h2 class="mt-6 text-center dom-title">
                    Drinks On Me
                </h2>
                <h3 class="mt-2 text-center text-sm text-black text-xl">
                    Hello there and welcome!
                </h3>
            </div>
            <Form @submit="submit" :validation-schema="validate">
                <div class="mt-12">
                    <div class="input-box">
                        <label for="email-address" class="text-xl">Email Address</label>
                        <div>
                            <Field id="email-address" name="email" type="email" autocomplete="email" required class="dom-border-input" v-model="login.email" />
                            <ErrorMessage name="email" class="input-error" />
                        </div>
                    </div>
                    <div class="input-box mb-8">
                        <label for="password" class="text-xl">Password</label>
                        <div>
                            <Field id="password" name="password" type="password" required class="dom-border-input" v-model="login.password" />
                            <ErrorMessage name="password" class="input-error" />
                        </div>
                    </div>
                </div>

                <div class="text-center mt-12">
                    <btn :type="'submit'" :loading="signInLoading">Sign In</btn>
                </div>

                <div class="text-center mt-8">
                    <router-link :to="{name:'ForgotPassword'}" class="text-xl">
                        Forgot Password?
                    </router-link>
                </div>

                <hr class="mt-12">

                <h3 class="mt-8 text-center text-sm text-black text-2xl">
                    <router-link :to="{name:'SignUp'}">
                        Looking for more customers?
                    </router-link>
                </h3>
            </Form>
        </div>
    </div>
</template>

<script>

import {object, string} from 'yup'

export default {
    name: "SignIn",
    data() {
        return {
            validate: object({
                email: string().email('Email must be a valid email').required('Please enter your email').nullable(),
                password: string().min(6, 'Password must be at least 6 characters').required('Password is required').nullable()
            }),
            signInLoading: false,
            login: {
                email: null,
                password: null
            },
        }
    },
    created() {
        document.title = "Sign In"
    },
    methods: {
        submit(){
            this.signInLoading = true;
            this.$api.login(this.login).then((response)=>{
                this.signInLoading = false;
                if(response && response.id && response.role == 'bar'){
                    this.$router.push({name: 'BrancheList'}) 
                }else if(response && response.id && response.role == 'super_admin'){
                    this.$router.push({name: 'Dashboard'}) 
                }
            }).catch(()=>{
                this.signInLoading = false;
            })
        }
    }
}
</script>