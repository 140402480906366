<template>
  <div
    class="
      min-h-full
      flex
      items-center
      justify-center
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="max-w-md w-full">
      <div>
        <h2 class="mt-6 text-center dom-title">Drinks On Me</h2>
        <h3 class="mt-2 text-center text-sm text-black text-xl">
          Trouble Logging In?
        </h3>
        <p class="mt-4 text-domGray text-center">
          Enter your email and we'll send you a link to get back into your
          account.
        </p>
      </div>
      <Form @submit="submit" :validation-schema="validate">
        <div class="mt-12">
          <div class="input-box">
            <label for="email-address" class="text-xl">Email Address</label>
            <div>
              <Field
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="dom-border-input"
                v-model="email"
              />
              <ErrorMessage name="email" class="input-error" />
            </div>
          </div>
        </div>

        <div class="text-center mt-12">
          <btn :type="'submit'" :loading="signInLoading">Submit</btn>
        </div>

        <div class="text-center mt-8">
          <router-link :to="{ name: 'Home' }"> Login </router-link>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>

import {object, string} from 'yup'

export default {
    name: "ForgotPassword",
    data() {
        return {
            validate: object({
                email: string().email('Email must be a valid email').required('Please enter your email').nullable(),
            }),
            signInLoading: false,
            email: null
        }
    },
    created() {
        document.title = "Sign In"
    },
    methods: {
        submit(){
            this.signInLoading = true;
            this.$api.post('send-forgot-password-link',{
                email: this.email
            }).then((response)=>{
                this.$toast.info(response.message);
                this.$router.push({name: 'Login'}) 
                this.signInLoading = false;
            }).catch(()=>{
                this.signInLoading = false;
            })
        }
    }
}
</script>