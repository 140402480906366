<template>
  <div
    class="
      min-h-full
      flex
      items-center
      justify-center
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="max-w-md w-full">
      <div class="text-center">
        <h2 class="mt-6 dom-title">Drinks On Me</h2>
        <h3 class="mt-2 text-sm text-black text-xl">
          Looking for more customers?
        </h3>
        <p>We bring them to you</p>
      </div>
      <Form
        @submit="submit"
        :validation-schema="validate"
        @invalid-submit="invalidSubmit"
      >
        <div class="grid grid-cols-2 gap-x-3 mt-12">
          <div class="input-box">
            <label for="first_name" class="text-xl">First Name</label>
            <div>
              <Field
                id="first_name"
                name="first_name"
                type="text"
                required
                class="dom-border-input"
                v-model="signup.first_name"
              />
              <ErrorMessage name="first_name" class="input-error" />
            </div>
          </div>
          <div class="input-box">
            <label for="last_name" class="text-xl">Last Name</label>
            <div>
              <Field
                id="last_name"
                name="last_name"
                type="text"
                required
                class="dom-border-input"
                v-model="signup.last_name"
              />
              <ErrorMessage name="last_name" class="input-error" />
            </div>
          </div>
        </div>

        <div class="input-box">
          <label for="email" class="text-xl">Email Address</label>
          <div>
            <Field
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="dom-border-input"
              v-model="signup.email"
            />
            <ErrorMessage name="email" class="input-error" />
          </div>
        </div>

        <div class="input-box">
          <label for="phone" class="text-xl">Phone #</label>
          <div>
            <Field
              id="phone"
              name="phone"
              type="tel"
              required
              class="dom-border-input"
              v-model="signup.phone"
            />
            <ErrorMessage name="phone" class="input-error" />
          </div>
        </div>

        <div class="input-box">
          <label for="password" class="text-xl">Password</label>
          <div>
            <Field
              id="password"
              name="password"
              type="password"
              required
              class="dom-border-input"
              v-model="signup.password"
            />
            <ErrorMessage name="password" class="input-error" />
          </div>
        </div>

        <div class="input-box">
          <label for="confirm_password" class="text-xl">Confirm Password</label>
          <div>
            <Field
              id="confirm_password"
              name="confirm_password"
              type="password"
              required
              class="dom-border-input"
              v-model="signup.confirm_password"
            />
            <ErrorMessage name="confirm_password" class="input-error" />
          </div>
        </div>

        <div class="input-box">
          <label for="business_name" class="text-xl">Business Name</label>
          <div>
            <Field
              id="business_name"
              name="business_name"
              type="business_name"
              required
              class="dom-border-input"
              v-model="signup.business_name"
            />
            <ErrorMessage name="business_name" class="input-error" />
          </div>
        </div>

        <div class="grid grid-cols-3 gap-x-3">
          <div class="input-box col-span-2">
            <label for="business_address" class="text-xl"
              >Business Address</label
            >
            <div>
              <Field
                id="business_address"
                name="business_address"
                type="text"
                required
                class="dom-border-input"
                v-model="signup.business_address"
              />
              <ErrorMessage name="business_address" class="input-error" />
            </div>
          </div>
          <div class="input-box">
            <label for="unit" class="text-xl">Unit#</label>
            <div>
              <Field
                id="unit"
                name="unit"
                type="text"
                required
                class="dom-border-input"
                v-model="signup.unit"
              />
              <ErrorMessage name="unit" class="input-error" />
            </div>
          </div>
        </div>

        <div class="input-box">
          <label for="city" class="text-xl">City</label>
          <div>
            <Field
              id="city"
              name="city"
              type="city"
              required
              class="dom-border-input"
              v-model="signup.city"
            />
            <ErrorMessage name="city" class="input-error" />
          </div>
        </div>

        <div class="grid grid-cols-2 gap-x-3">
          <div class="input-box">
            <label for="state" class="text-xl">State</label>
            <div>
              <Field
                id="state"
                name="state"
                type="text"
                required
                class="dom-border-input"
                v-model="signup.state"
              />
              <ErrorMessage name="state" class="input-error" />
            </div>
          </div>
          <div class="input-box">
            <label for="zipcode" class="text-xl">Zipcode</label>
            <div>
              <Field
                id="zipcode"
                name="zipcode"
                type="text"
                required
                class="dom-border-input"
                v-model="signup.zipcode"
              />
              <ErrorMessage name="zipcode" class="input-error" />
            </div>
          </div>
        </div>

        <div class="text-center mt-12">
          <btn :loading="signUpLoading" :black="true" :type="'submit'"
            >Sign Up</btn
          >
        </div>

        <hr class="mt-12" />

        <h3 class="mt-8 text-center text-sm text-black text-2xl">
          <router-link :to="{ name: 'Home' }">
            Already registered? Sign In
          </router-link>
        </h3>
      </Form>
    </div>
  </div>
</template>

<script>

import {object, string} from 'yup'

export default {
    name: "SignUp",
    data() {
        return {
            validate: object({
                first_name: string().min(2,'Please enter your first name').required('Please enter your first name').nullable(),
                last_name: string().min(2,'Please enter your last name').required('Please enter your last name').nullable(),
                phone: string().min(10,'Please enter your phone number').required('Please enter your phone number').nullable(),
                password: string().min(6,'Password should be at least 6 characters long').required('Please enter password').nullable(),
                confirm_password: string().required().test('passwords-match','Passwords must match',(value) => this.signup.password === value),
                email: string().email('Email must be a valid email').required('Please enter your email').nullable(),
                business_name: string().min(2,'Please enter your business name').required('Please enter your business name').nullable(),
                business_address: string().min(2, 'Please enter your business address').required('Please enter your business address').nullable(),
                unit: string().required('Please enter unit number').nullable(),
                city: string().min(2, 'Please enter city name').required('Please enter city name').nullable(),
                state: string().min(2, 'Please enter state name.').required('Please enter state name.').nullable(),
                zipcode: string().min(5, 'Please enter zipcode').required('Please enter zipcode').nullable()
            }),
            signUpLoading: false,
            signup: {
                first_name: null,
                last_name: null,
                phone: null,
                password: null,
                confirm_password: null,
                email: null,
                business_name: null,
                business_address: null,
                unit: null,
                city: null,
                state: null,
                zipcode: null,
            },
            // signup: {
            //     first_name: "Olbuz",
            //     last_name: "Dev",
            //     phone: "1234567890",
            //     password: "123456",
            //     email: "sandeep.solanki2010@gmail.com",
            //     business_name: "Olbuz1",
            //     business_address: "Gota Gota",
            //     unit: "803",
            //     city: "Karnavati",
            //     state: "Gujarat",
            //     zipcode: "382480",
            // }
        }
    },
    created() {
        document.title = "Sign Up"
    },
    methods: {
        submit(){
            this.signUpLoading = true;
            this.$api.post('bar/register',this.signup).then((response)=>{
                this.$toast.info(response.message)
                this.$router.push({name: 'Home'})
            }).catch(()=>{
                this.signUpLoading = false;
            })
        },
        invalidSubmit () {
            var domRect = document.querySelector('.input-error').getBoundingClientRect()
            window.scrollTo(
                domRect.left + document.documentElement.scrollLeft,
                domRect.top + document.documentElement.scrollTop - 100
            )
        }
    }
}
</script>